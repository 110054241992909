/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait = 200, immediate = true) {
  let timeout, _args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, _args)
        if (!timeout) context = _args = null
      }
    }
  }

  return function (...args) {
    _args = args
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = _args = null
    }

    return result
  }
}

export function getUrlQuery(queryName) {
  var reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)");
  console.log(window.location.search, 133)
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return r[2];
  return null;
}