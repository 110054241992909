import axios from 'axios'
import Vue from 'vue'
import { contentType, debounce, requestTimeout, successCode } from '@/config'
import { isArray } from '@/utils/validate'
import { getUserToken } from '@/utils/auth'
import qs from 'qs'
import store from '@/store'
import { getUrlQuery } from '@/utils/index'

let loadingInstance

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = async (code, msg) => {
	const appKey = store.getters.appKey
	const appSerect = store.getters.appSerect
	switch (code) {
		case 500:
			Vue.prototype.$notify.error({ title: '服务器异常', message: msg })
			break
		case '10002':
			Vue.prototype.$notify.info({
				title: 'token过期',
				message: '重新获取token中...'
			})
			await store.dispatch('handlerYingshiyunToken/getAccessToken', {
				appKey,
				appSerect
			})
			break
		default:
			Vue.prototype.$notify.error({
				title: `服务器异常`,
				message: `${code}:${msg}`
			})
			break
	}
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description axios初始化
 */
const instance = axios.create({
	baseURL: 'https://www.gonglushuiyun.com:62443',
	timeout: requestTimeout,
	headers: {
		'Content-Type': contentType
	}
})



/**
 * @author chuzhixin 1204505056@qq.com
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
	(config) => {
		const token = getUrlQuery("access_token")
		if (token) config.headers['Authorization'] = token
		if (
			config.data &&
			config.headers['Content-Type'] ===
			'application/x-www-form-urlencoded;charset=UTF-8'
		)
			// if (config.data && config.data.baseURL) {
			// 	config.baseURL = config.data.baseURL
			// } else {

			// }
			config.data = qs.stringify(config.data)
		if (debounce.some((item) => config.url.includes(item))) {
			//这里写加载动画
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

/**
 * @author chuzhixin 1204505056@qq.com
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
	(response) => {
		if (loadingInstance) loadingInstance.close()
		const { data, config, request } = response
		if (config.responseType === 'blob') return data
		const { status, code, msg, success } = data
		// 操作正常Code数组
		const codeVerificationArray = isArray(successCode)
			? [...successCode]
			: [...[successCode]]
		if (request._url.indexOf('pmcp.zjkgs.cn') == -1) {
			// 是否操作正常
			if (codeVerificationArray.includes(status) || success || code == 200) {
				return data
			} else {
				handleCode(status, msg)
				return Promise.reject(
					JSON.stringify({ url: config.url, status: status || code, msg }) ||
					'请检查你的网络'
				)
			}
		} else {
			return data
		}
	},
	(error) => {
		if (loadingInstance) loadingInstance.close()
		const { response, message } = error
		if (error.response && error.response.data) {
			const { status, data } = response
			handleCode(status, data.msg || message)
			return Promise.reject(error)
		} else {
			let { message } = error
			if (message === 'Network Error') {
				message = '后端接口连接异常'
			}
			if (message.includes('timeout')) {
				message = '后端接口请求超时'
			}
			if (message.includes('Request failed with status code')) {
				const code = message.substr(message.length - 3)
				message = '后端接口' + code + '异常'
			}
			message.error(message || `后端接口未知异常`)
			return Promise.reject(error)
		}
	}
)

export default instance