export const locationMarkList = [
  // { pos: [118.857925, 28.943321], type: 'bridge', title: '起点' },
  // { pos: [119.001336, 28.993837], type: 'bridge', title: '湖镇锚地' },
  // { pos: [119.233408, 29.060725], type: 'bridge', title: '杨家大桥' },
  // { pos: [119.164457, 29.045061], type: 'bridge', title: '虎头山大桥' },
  // { pos: [119.144154, 29.036392], type: 'bridge', title: '施工单位驻地' },
  // { pos: [119.101792, 29.030596], type: 'bridge', title: '团石2#锚地' },
  // { pos: [119.057685, 29.017227], type: 'bridge', title: '高安大桥' },
  // { pos: [119.013059, 28.989408], type: 'bridge', title: '全咨指挥部驻地' },
  // { pos: [118.985508, 29.001904], type: 'bridge', title: '塔底锚地' },
  // { pos: [118.977323, 29.001448], type: 'bridge', title: '建设单位驻地' },
  // { pos: [118.897437, 28.977949], type: 'bridge', title: '信安湖服务区' },
  // { pos: [119.310178, 29.084], type: 'bridge', title: '终点' }

  { pos: [119.220958, 29.05702456], type: 'bridge', title: '杨家大桥' },
  { pos: [119.29020477, 29.07728715], type: 'bridge', title: '湖镇锚地' },
  { pos: [119.23750496, 29.06071537], type: 'bridge', title: '龙游服务区扩建' },
  { pos: [119.09950051, 29.03776786], type: 'bridge', title: '团石2#锚地' },
  { pos: [118.99035233, 29.00493994], type: 'bridge', title: '塔底锚地' },
  { pos: [118.90240194, 28.9829369], type: 'bridge', title: '信安湖服务区', camera: [118.90240194, 27.9829369] },
  { type: 'image', title: '', pos: [118.90810194, 28.970369], images: [{ name: 'camera.png', class: 'camera' }], url: 'https://bimcase.cn/hysystem/control/index.html' },
]

// export const roadPath = [
//   [119.310178, 29.084],
//   [119.308567, 29.083061],
//   [119.307224, 29.082826],
//   [119.306418, 29.082591],
//   [119.305075, 29.082591],
//   [119.304001, 29.082357],
//   [119.30239, 29.081887],
//   [119.301584, 29.081418],
//   [119.30051, 29.081418],
//   [119.299167, 29.081183],
//   [119.297824, 29.080949],
//   [119.296213, 29.080949],
//   [119.294602, 29.080714],
//   [119.293527, 29.080479],
//   [119.291916, 29.080479],
//   [119.290573, 29.080244],
//   [119.288962, 29.08001],
//   [119.287619, 29.08001],
//   [119.286276, 29.08001],
//   [119.285202, 29.08001],
//   [119.283859, 29.08001],
//   [119.282785, 29.08001],
//   [119.281174, 29.079306],
//   [119.280099, 29.079071],
//   [119.278488, 29.078601],
//   [119.277682, 29.078132],
//   [119.275802, 29.077663],
//   [119.274728, 29.076958],
//   [119.273654, 29.076724],
//   [119.272311, 29.076489],
//   [119.271237, 29.076254],
//   [119.269894, 29.075785],
//   [119.26882, 29.075315],
//   [119.267477, 29.074377],
//   [119.266672, 29.074142],
//   [119.264523, 29.073672],
//   [119.263717, 29.073438],
//   [119.262375, 29.073203],
//   [119.2613, 29.072968],
//   [119.260495, 29.072264],
//   [119.259152, 29.072264],
//   [119.256735, 29.072029],
//   [119.255392, 29.07156],
//   [119.253512, 29.07156],
//   [119.252438, 29.071325],
//   [119.250827, 29.071325],
//   [119.249484, 29.071325],
//   [119.247872, 29.071325],
//   [119.246798, 29.071325],
//   [119.245187, 29.071325],
//   [119.243575, 29.071325],
//   [119.242501, 29.071325],
//   [119.241696, 29.070856],
//   [119.240621, 29.070621],
//   [119.240084, 29.069917],
//   [119.23901, 29.069447],
//   [119.237936, 29.068743],
//   [119.237667, 29.067804],
//   [119.236862, 29.0671],
//   [119.236056, 29.066631],
//   [119.234713, 29.065457],
//   [119.234176, 29.064988],
//   [119.23337, 29.064049],
//   [119.232565, 29.063579],
//   [119.232027, 29.06264],
//   [119.230953, 29.061936],
//   [119.229879, 29.061466],
//   [119.228805, 29.060762],
//   [119.227462, 29.060527],
//   [119.225851, 29.060293],
//   [119.224508, 29.059588],
//   [119.223434, 29.059588],
//   [119.222628, 29.059119],
//   [119.221285, 29.058415],
//   [119.220211, 29.057945],
//   [119.219137, 29.057476],
//   [119.218331, 29.057241],
//   [119.217257, 29.056771],
//   [119.216183, 29.056067],
//   [119.21484, 29.055598],
//   [119.213497, 29.055598],
//   [119.212423, 29.055128],
//   [119.21108, 29.055128],
//   [119.210274, 29.054658],
//   [119.2092, 29.054424],
//   [119.207857, 29.054189],
//   [119.206783, 29.05325],
//   [119.205172, 29.053015],
//   [119.203829, 29.052311],
//   [119.202217, 29.052076],
//   [119.200606, 29.052076],
//   [119.199532, 29.052076],
//   [119.198458, 29.052076],
//   [119.197115, 29.052076],
//   [119.196041, 29.052076],
//   [119.193892, 29.051372],
//   [119.192549, 29.051372],
//   [119.191207, 29.051137],
//   [119.189864, 29.051137],
//   [119.189058, 29.051372],
//   [119.188252, 29.051841],
//   [119.18691, 29.052076],
//   [119.185835, 29.052311],
//   [119.184493, 29.052311],
//   [119.183418, 29.052311],
//   [119.182344, 29.052311],
//   [119.181001, 29.052546],
//   [119.179927, 29.052546],
//   [119.178584, 29.052311],
//   [119.177242, 29.052076],
//   [119.176167, 29.051841],
//   [119.175362, 29.051137],
//   [119.174556, 29.050433],
//   [119.173213, 29.049963],
//   [119.172139, 29.049494],
//   [119.171065, 29.04832],
//   [119.170528, 29.04785],
//   [119.170259, 29.047146],
//   [119.16999, 29.045972],
//   [119.169453, 29.045033],
//   [119.169185, 29.043859],
//   [119.169185, 29.04292],
//   [119.168916, 29.041981],
//   [119.168379, 29.041276],
//   [119.167842, 29.040572],
//   [119.167573, 29.039398],
//   [119.167305, 29.038693],
//   [119.166499, 29.037285],
//   [119.165694, 29.03658],
//   [119.165156, 29.035876],
//   [119.164619, 29.035406],
//   [119.163814, 29.034702],
//   [119.162739, 29.034232],
//   [119.161397, 29.033528],
//   [119.160054, 29.033293],
//   [119.158442, 29.032354],
//   [119.1571, 29.032119],
//   [119.156025, 29.031884],
//   [119.154414, 29.031884],
//   [119.15334, 29.031884],
//   [119.151728, 29.032119],
//   [119.150923, 29.032354],
//   [119.149311, 29.032354],
//   [119.1477, 29.032588],
//   [119.14582, 29.033058],
//   [119.144746, 29.033058],
//   [119.143135, 29.033058],
//   [119.141523, 29.033058],
//   [119.140718, 29.032588],
//   [119.139643, 29.032354],
//   [119.137763, 29.031884],
//   [119.136689, 29.031649],
//   [119.134541, 29.031649],
//   [119.133198, 29.03118],
//   [119.131855, 29.03118],
//   [119.130512, 29.03118],
//   [119.129438, 29.03118],
//   [119.128095, 29.03118],
//   [119.127021, 29.03118],
//   [119.125141, 29.03118],
//   [119.124067, 29.030945],
//   [119.122456, 29.030945],
//   [119.121381, 29.030945],
//   [119.120039, 29.030945],
//   [119.118964, 29.030945],
//   [119.117353, 29.031414],
//   [119.116547, 29.030945],
//   [119.115742, 29.03118],
//   [119.114667, 29.031414],
//   [119.113593, 29.031649],
//   [119.112787, 29.032119],
//   [119.111713, 29.032588],
//   [119.111176, 29.033763],
//   [119.11037, 29.034467],
//   [119.109833, 29.035406],
//   [119.108491, 29.035876],
//   [119.107416, 29.036111],
//   [119.106074, 29.036345],
//   [119.104731, 29.036345],
//   [119.103656, 29.03658],
//   [119.102851, 29.036345],
//   [119.101777, 29.036345],
//   [119.100434, 29.036345],
//   [119.09936, 29.036111],
//   [119.098017, 29.035641],
//   [119.096674, 29.035406],
//   [119.0956, 29.034937],
//   [119.094526, 29.034702],
//   [119.092914, 29.034467],
//   [119.091571, 29.034232],
//   [119.090229, 29.034232],
//   [119.089154, 29.033997],
//   [119.087812, 29.033997],
//   [119.086469, 29.033997],
//   [119.085126, 29.033997],
//   [119.084052, 29.033763],
//   [119.082977, 29.033293],
//   [119.081098, 29.033293],
//   [119.079486, 29.033293],
//   [119.078412, 29.033293],
//   [119.077069, 29.033058],
//   [119.076264, 29.033293],
//   [119.075189, 29.033293],
//   [119.074115, 29.033528],
//   [119.073041, 29.033763],
//   [119.071698, 29.033763],
//   [119.070624, 29.033763],
//   [119.06955, 29.033293],
//   [119.067938, 29.032588],
//   [119.066864, 29.032588],
//   [119.065521, 29.032588],
//   [119.064447, 29.032588],
//   [119.063104, 29.032588],
//   [119.061761, 29.032588],
//   [119.060419, 29.032354],
//   [119.059613, 29.031884],
//   [119.059076, 29.031414],
//   [119.058807, 29.03071],
//   [119.057733, 29.029771],
//   [119.057464, 29.028831],
//   [119.057196, 29.027892],
//   [119.057196, 29.026953],
//   [119.057196, 29.025309],
//   [119.057196, 29.02437],
//   [119.057196, 29.023196],
//   [119.056927, 29.022021],
//   [119.056927, 29.020612],
//   [119.056927, 29.019673],
//   [119.057196, 29.018968],
//   [119.057464, 29.018029],
//   [119.057464, 29.01709],
//   [119.057196, 29.01615],
//   [119.056659, 29.015446],
//   [119.05639, 29.014741],
//   [119.055316, 29.014506],
//   [119.054779, 29.013567],
//   [119.053973, 29.013097],
//   [119.05263, 29.012158],
//   [119.052093, 29.011453],
//   [119.051288, 29.010983],
//   [119.050213, 29.010279],
//   [119.049408, 29.009574],
//   [119.048602, 29.00887],
//   [119.047528, 29.008165],
//   [119.046991, 29.00746],
//   [119.046722, 29.006521],
//   [119.046185, 29.005816],
//   [119.045916, 29.005112],
//   [119.045648, 29.003937],
//   [119.045379, 29.002998],
//   [119.045379, 29.001823],
//   [119.044842, 29.001119],
//   [119.044842, 29.000179],
//   [119.044842, 28.999005],
//   [119.045379, 28.9983],
//   [119.045916, 28.997126],
//   [119.046185, 28.996186],
//   [119.046454, 28.995481],
//   [119.046722, 28.994542],
//   [119.047259, 28.994072],
//   [119.047259, 28.993132],
//   [119.047259, 28.991958],
//   [119.046991, 28.991018],
//   [119.046454, 28.990079],
//   [119.045648, 28.989609],
//   [119.044842, 28.989139],
//   [119.044305, 28.988669],
//   [119.043231, 28.988434],
//   [119.042694, 28.98773],
//   [119.041082, 28.987025],
//   [119.040277, 28.986555],
//   [119.038665, 28.98632],
//   [119.037591, 28.985615],
//   [119.036517, 28.98538],
//   [119.035174, 28.985145],
//   [119.033831, 28.985145],
//   [119.032757, 28.985145],
//   [119.031414, 28.984911],
//   [119.03034, 28.984911],
//   [119.029266, 28.984911],
//   [119.027654, 28.984911],
//   [119.02658, 28.984911],
//   [119.025237, 28.984911],
//   [119.024163, 28.984911],
//   [119.022552, 28.984911],
//   [119.021746, 28.98538],
//   [119.020672, 28.985615],
//   [119.019061, 28.985615],
//   [119.017449, 28.985615],
//   [119.016375, 28.986085],
//   [119.014764, 28.98632],
//   [119.013689, 28.986555],
//   [119.012347, 28.986555],
//   [119.011541, 28.98679],
//   [119.010735, 28.987025],
//   [119.009661, 28.987495],
//   [119.009124, 28.987964],
//   [119.008318, 28.988669],
//   [119.006707, 28.989374],
//   [119.00617, 28.989844],
//   [119.005633, 28.990314],
//   [119.005096, 28.991253],
//   [119.003753, 28.991723],
//   [119.003216, 28.992193],
//   [119.002679, 28.992663],
//   [119.001873, 28.993367],
//   [119.001336, 28.993837],
//   [119.000799, 28.994542],
//   [119.000261, 28.995481],
//   [118.999993, 28.996186],
//   [118.999724, 28.996891],
//   [118.99865, 28.998065],
//   [118.99865, 28.999005],
//   [118.998113, 29.000179],
//   [118.997844, 29.001119],
//   [118.997307, 29.001589],
//   [118.996233, 29.002058],
//   [118.995427, 29.002528],
//   [118.994353, 29.002998],
//   [118.99301, 29.003468],
//   [118.992205, 29.003937],
//   [118.99113, 29.004172],
//   [118.990325, 29.004877],
//   [118.988982, 29.004877],
//   [118.987908, 29.004407],
//   [118.985759, 29.003937],
//   [118.984417, 29.003937],
//   [118.983074, 29.003703],
//   [118.981999, 29.003937],
//   [118.981462, 29.004877],
//   [118.98012, 29.005347],
//   [118.979314, 29.005582],
//   [118.978508, 29.005347],
//   [118.977165, 29.004877],
//   [118.97636, 29.004407],
//   [118.975554, 29.003937],
//   [118.974748, 29.003468],
//   [118.973674, 29.002763],
//   [118.972869, 29.001823],
//   [118.971794, 29.000884],
//   [118.971257, 29.000179],
//   [118.970451, 28.999475],
//   [118.969377, 28.999005],
//   [118.968303, 28.9983],
//   [118.96696, 28.997361],
//   [118.966423, 28.996891],
//   [118.96508, 28.995716],
//   [118.964006, 28.995012],
//   [118.962932, 28.994307],
//   [118.962395, 28.993837],
//   [118.96132, 28.992663],
//   [118.959978, 28.991253],
//   [118.959709, 28.990314],
//   [118.958635, 28.989374],
//   [118.958098, 28.988669],
//   [118.957292, 28.98773],
//   [118.957024, 28.98679],
//   [118.955949, 28.986085],
//   [118.955144, 28.985145],
//   [118.954069, 28.984911],
//   [118.952995, 28.984441],
//   [118.951384, 28.983971],
//   [118.950041, 28.983971],
//   [118.948967, 28.983971],
//   [118.947624, 28.983266],
//   [118.94655, 28.982796],
//   [118.94467, 28.982326],
//   [118.943596, 28.981622],
//   [118.942253, 28.981387],
//   [118.940641, 28.980917],
//   [118.938762, 28.980917],
//   [118.936882, 28.980917],
//   [118.935807, 28.980917],
//   [118.934465, 28.980917],
//   [118.932853, 28.980917],
//   [118.931242, 28.980917],
//   [118.929899, 28.980917],
//   [118.928556, 28.980917],
//   [118.927482, 28.980917],
//   [118.926676, 28.981622],
//   [118.926139, 28.982091],
//   [118.925602, 28.982796],
//   [118.924797, 28.983031],
//   [118.923722, 28.983736],
//   [118.922648, 28.983971],
//   [118.921305, 28.984441],
//   [118.920231, 28.985145],
//   [118.919157, 28.98538],
//   [118.917545, 28.986085],
//   [118.916471, 28.98632],
//   [118.915128, 28.98632],
//   [118.914054, 28.98632],
//   [118.912174, 28.98632],
//   [118.910563, 28.986085],
//   [118.909757, 28.985615],
//   [118.908952, 28.985145],
//   [118.907609, 28.984441],
//   [118.906803, 28.983736],
//   [118.905997, 28.983266],
//   [118.905192, 28.982796],
//   [118.904655, 28.981857],
//   [118.904386, 28.980917],
//   [118.904118, 28.980212],
//   [118.90358, 28.979272],
//   [118.903043, 28.978333],
//   [118.902506, 28.977628],
//   [118.901701, 28.976923],
//   [118.900895, 28.975748],
//   [118.900358, 28.975043],
//   [118.899283, 28.974339],
//   [118.898209, 28.973869],
//   [118.897135, 28.973634],
//   [118.896061, 28.973399],
//   [118.894987, 28.973399],
//   [118.893375, 28.973399],
//   [118.892301, 28.973399],
//   [118.890958, 28.973399],
//   [118.890152, 28.973634],
//   [118.889078, 28.973869],
//   [118.888004, 28.974339],
//   [118.88693, 28.974574],
//   [118.885856, 28.975043],
//   [118.88505, 28.975748],
//   [118.884513, 28.976218],
//   [118.883439, 28.977158],
//   [118.88317, 28.977863],
//   [118.882901, 28.978567],
//   [118.882364, 28.979507],
//   [118.881559, 28.980212],
//   [118.880216, 28.980917],
//   [118.878336, 28.982091],
//   [118.87753, 28.982326],
//   [118.876993, 28.981857],
//   [118.876456, 28.981387],
//   [118.875382, 28.980917],
//   [118.874576, 28.980212],
//   [118.874039, 28.979742],
//   [118.872965, 28.979037],
//   [118.872428, 28.978098],
//   [118.871891, 28.977628],
//   [118.871353, 28.976923],
//   [118.870279, 28.975983],
//   [118.869473, 28.975513],
//   [118.868936, 28.974574],
//   [118.868399, 28.973869],
//   [118.868131, 28.973164],
//   [118.867594, 28.972224],
//   [118.867325, 28.970814],
//   [118.867325, 28.969875],
//   [118.867325, 28.9687],
//   [118.866788, 28.967525],
//   [118.866519, 28.96635],
//   [118.866251, 28.96541],
//   [118.865445, 28.96447],
//   [118.864639, 28.963296],
//   [118.864102, 28.962591],
//   [118.863834, 28.961416],
//   [118.863297, 28.960241],
//   [118.86276, 28.959301],
//   [118.861954, 28.958596],
//   [118.861685, 28.957421],
//   [118.861685, 28.956481],
//   [118.861148, 28.955071],
//   [118.86088, 28.954366],
//   [118.860611, 28.953426],
//   [118.860342, 28.952251],
//   [118.860074, 28.951311],
//   [118.859537, 28.950136],
//   [118.859268, 28.949431],
//   [118.859, 28.948491],
//   [118.858731, 28.947316],
//   [118.858463, 28.946376],
//   [118.858463, 28.945201],
//   [118.858194, 28.944496],
//   [118.857925, 28.943321]
// ]

export const roadPath = [
  [118.85759093, 28.94316147],
  [118.85851463, 28.94404783],
  [118.85974709, 28.9457811],
  [118.86021498, 28.94686307],
  [118.86065106, 28.94826395],
  [118.86089376, 28.95049769],
  [118.86554152, 28.96613148],
  [118.86572076, 28.96663211],
  [118.8684825, 28.97220558],
  [118.86955924, 28.97435984],
  [118.87031315, 28.9755968],
  [118.87104666, 28.97648024],
  [118.87127591, 28.97671544],
  [118.87485704, 28.98026379],
  [118.87725045, 28.9815654],
  [118.8795523, 28.98162048],
  [118.8816814, 28.98070933],
  [118.88273242, 28.97963657],
  [118.88322, 28.97869676],
  [118.88375314, 28.97722708],
  [118.88418678, 28.97641609],
  [118.88484393, 28.97573149],
  [118.88690673, 28.97407901],
  [118.88796298, 28.97343254],
  [118.88917213, 28.97304925],
  [118.89176384, 28.97255189],
  [118.89657529, 28.97290985],
  [118.90032391, 28.97558975],
  [118.90431882, 28.98079787],
  [118.9058801, 28.98229111],
  [118.90794963, 28.98386734],
  [118.9100793, 28.98480898],
  [118.91355541, 28.98591419],
  [118.91776016, 28.98625592],
  [118.92159695, 28.98470846],
  [118.92546098, 28.98190444],
  [118.92673376, 28.98133512],
  [118.92816177, 28.98129462],
  [118.93056334, 28.9817135],
  [118.93553922, 28.98289174],
  [118.93782656, 28.98328079],
  [118.93857042, 28.98332959],
  [118.9393666, 28.98322963],
  [118.94253111, 28.98246927],
  [118.94480945, 28.98221437],
  [118.94678649, 28.98245021],
  [118.955446, 28.98474459],
  [118.95695105, 28.98539457],
  [118.95813944, 28.98643426],
  [118.96207963, 28.99123951],
  [118.96287943, 28.99211565],
  [118.96377795, 28.99291483],
  [118.97214499, 28.99962542],
  [118.97393254, 29.00049672],
  [118.97596377, 29.00069017],
  [118.97980434, 29.00038107],
  [118.98117893, 29.00043241],
  [118.98249016, 29.00080114],
  [118.98763424, 29.00294707],
  [118.99008102, 29.00353553],
  [118.99228949, 29.00341349],
  [118.99445374, 29.00265541],
  [118.99627923, 29.00127422],
  [119.00044716, 28.99684556],
  [119.00068391, 28.99661114],
  [119.00093871, 28.99639176],
  [119.00440907, 28.99355158],
  [119.00898864, 28.9903778],
  [119.01231272, 28.98770876],
  [119.01554722, 28.98571598],
  [119.01821456, 28.98475506],
  [119.02146923, 28.98421119],
  [119.02499937, 28.98431614],
  [119.04171041, 28.98650327],
  [119.04443344, 28.98747888],
  [119.04588719, 28.98878086],
  [119.0469527, 28.99137436],
  [119.04685656, 28.99300781],
  [119.04473172, 29.00173299],
  [119.044555, 29.00348856],
  [119.04476619, 29.00488534],
  [119.04550175, 29.00661427],
  [119.0463278, 29.0077283],
  [119.04679181, 29.0082437],
  [119.04848437, 29.00965639],
  [119.05070557, 29.01064138],
  [119.05235034, 29.01114346],
  [119.05398811, 29.0118286],
  [119.05541613, 29.01281124],
  [119.05768723, 29.01476334],
  [119.05956682, 29.01722857],
  [119.05995594, 29.0183139],
  [119.06010725, 29.02077044],
  [119.05968225, 29.02237313],
  [119.05772833, 29.02714843],
  [119.05754659, 29.02900046],
  [119.05813969, 29.03032128],
  [119.05952884, 29.03149151],
  [119.06125644, 29.03197375],
  [119.06691348, 29.03240179],
  [119.06782647, 29.03250703],
  [119.06872521, 29.03268348],
  [119.07236381, 29.03354916],
  [119.07339504, 29.03373324],
  [119.07444433, 29.03380022],
  [119.08060824, 29.03384556],
  [119.08135642, 29.03386279],
  [119.08210002, 29.03390367],
  [119.09159236, 29.034575],
  [119.0922405, 29.03463893],
  [119.09288273, 29.03473886],
  [119.10426493, 29.03683642],
  [119.10575508, 29.03691555],
  [119.10684199, 29.03672788],
  [119.10808309, 29.03621956],
  [119.10903315, 29.03552999],
  [119.11463832, 29.03034725],
  [119.11526047, 29.02987642],
  [119.11601179, 29.02951051],
  [119.11703847, 29.02925983],
  [119.11764999, 29.02922437],
  [119.12826717, 29.02930935],
  [119.1286092, 29.02931726],
  [119.12928544, 29.02939046],
  [119.14194264, 29.03059986],
  [119.14682171, 29.03119472],
  [119.14787606, 29.03127456],
  [119.1489343, 29.03125809],
  [119.15300291, 29.03100891],
  [119.15687885, 29.03109511],
  [119.16066539, 29.03182288],
  [119.16242199, 29.03231795],
  [119.16530599, 29.03386654],
  [119.16706098, 29.03640556],
  [119.17062625, 29.04624254],
  [119.17147816, 29.04765715],
  [119.17283788, 29.04871923],
  [119.18134802, 29.05327967],
  [119.18279564, 29.05370022],
  [119.18430703, 29.05351694],
  [119.18982071, 29.0516656],
  [119.19178768, 29.05119596],
  [119.19382048, 29.0510783],
  [119.20379083, 29.05137726],
  [119.20561981, 29.05157839],
  [119.20737782, 29.05206506],
  [119.23331627, 29.06158398],
  [119.23392278, 29.06177892],
  [119.23454678, 29.06192511],
  [119.24679875, 29.06428321],
  [119.25174596, 29.06526524],
  [119.25515367, 29.06592241],
  [119.25650304, 29.06647744],
  [119.25745148, 29.0674877],
  [119.25892358, 29.07004512],
  [119.25984508, 29.07110224],
  [119.26113969, 29.07179408],
  [119.27469081, 29.07629087],
  [119.27539587, 29.07655162],
  [119.2760782, 29.07685829],
  [119.27895539, 29.0782722],
  [119.28249173, 29.0795351],
  [119.28627244, 29.07999734],
  [119.29334778, 29.08008711],
  [119.29524154, 29.08021393],
  [119.29710369, 29.08054408],
  [119.31113682, 29.08383307]
]

export default {
  locationMarkList,
  roadPath
}
