<template>
  <div>
    <!-- <div class="start-btn btn2">zoom: {{ zoom }} center: {{ center }}</div>
    <div class="start-btn btn3"><a @click="getPitch">获取当前pitch</a></div>
    <div class="start-btn btn3"><a @click="getCenter">获取中心点</a></div> -->
    <div id="map">
      <div v-show="!isHideOperationBar" :class="`operation-container ${
          styleType === 'light' ? 'operation-light' : 'operation-dark'
        }`">
        <div v-if="isShowPorjectInfo" class="operation-icon" @click="onProjectInfo">
          <img src="../../../assets/amap/jianjie.png" alt="" />
        </div>
        <div class="operation-icon" @mouseover="isStatusMenuShow = true" @mouseleave="isStatusMenuShow = false">
          <img v-if="styleType === 'light'" src="../../../assets/amap/changeMapStatus.png" alt="" />
          <img v-else src="../../../assets/amap/changeMapStatusDark.png" alt="" />
          <ul @mouseover="isStatusMenuShow = true" @mouseleave="isStatusMenuShow = false" class="menu-group"
            v-show="isStatusMenuShow">
            <li class="menu-item" @click="setMapStatus(statusItem.type)" v-for="statusItem in statusList"
              :key="statusItem.type">
              {{ statusItem.title }}
            </li>
          </ul>
        </div>
        <div class="operation-icon" @mouseover="isDbMenuShow = true" @mouseleave="isDbMenuShow = false">
          <img v-if="styleType === 'light'" src="../../../assets/amap/queryDb.png" alt="" />
          <img v-else src="../../../assets/amap/queryDbDark.png" alt="" />
          <ul @mouseover="isDbMenuShow = true" @mouseleave="isDbMenuShow = false" class="menu-group"
            v-show="isDbMenuShow">
            <li class="menu-item" @click="queryBd(loca)" v-for="loca in locationMarkList.filter(
                (item) => item[showMenuKey]
              )" :key="loca[showMenuKey]">
              <el-tooltip effect="dark" :content="loca[showMenuKey]" placement="right">
                <div>{{ loca[showMenuKey] }}</div>
              </el-tooltip>
            </li>
          </ul>
        </div>
        <div class="operation-icon" @click="refresh" v-if="$parent.projectId == 89">
          <i class="el-icon-refresh-left"></i>
        </div>
        <div v-show="isShow3D" class="operation-icon" @mouseover="isPlayMenuShow = true"
          @mouseleave="isPlayMenuShow = false">
          <img v-if="styleType === 'light'" src="../../../assets/amap/play.png" alt="" />
          <img v-else src="../../../assets/amap/playDark.png" alt="" />
          <ul @mouseover="isPlayMenuShow = true" @mouseleave="isPlayMenuShow = false" class="menu-group"
            v-show="isPlayMenuShow">
            <li class="menu-item" v-show="!isPlaying" @click="onStart">
              播放动画
            </li>
            <li class="menu-item" v-show="isPlaying && isPause" @click="onResume">
              继续播放
            </li>
            <li class="menu-item" v-show="isPlaying && !isPause" @click="onPause">
              暂停
            </li>
            <li class="menu-item" v-show="isPlaying" @click="onCancel">停止</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AMapLoader from '@amap/amap-jsapi-loader'

  export default {
    data() {
      return {
        isPause: false,
        isPlaying: false,
        isDbMenuShow: false,
        isStatusMenuShow: false,
        isPlayMenuShow: false,
        finished: true,
        polyline: {}, // 线路
        loca: {}, // 控制3d对象
        boatMarker: {}, // 标签图标
        map: {}, // 地图
        mapStatus: '2D',
        satelliteLayer: {}, // 卫星图层
        // centerPos: [118.723712, 28.941258],
        // centerPos: [118.68629, 28.936901],
        // defaultZoom: 11.5,
        zoom: 0,
        center: [0, 0],
        pitch: 0,
        locationMarkInfoList: [],
        timeInterval: '',
        timeOut: '',
        AMap: '',
        oldMaker: '',
        carPolylineList: [],
        airRecordlineList: [],
        carPointMarks: [],
        boarPointMarks: [],
      }
    },
    props: {
      isShow3D: {
        type: Boolean,
        default: false
      },
      isShowPorjectInfo: {
        type: Boolean,
        default: false
      },
      styleType: {
        type: String,
        default: 'dark'
      },
      mapkey: String,
      securityJsCode: String,
      mapStyle: String,
      roadPath: Array,
      roadPathColor: String,
      roadLineCap: String,
      initMapStatus: {
        type: String,
        default: '2D'
      },
      showMenuKey: {
        type: String,
        default: 'title'
      },
      isBoatMarker: {
        type: Boolean,
        default: true
      },
      isHideOperationBar: {
        type: Boolean,
        default: false
      },
      branchPathList: {
        type: Array,
        default: () => []
      },
      roadColor: {
        type: String,
        default: '#00FF51'
      },
      roadOpacity: {
        type: Number,
        default: 1
      },
      markerPathList: {
        type: Array,
        default: () => []
      },
      centerPos: {
        type: Array,
        default: () => [118.68629, 28.936901]
      },
      defaultZoom: {
        type: Number,
        default: 11.5
      },
      locationMarkList: Array,
      directionMarkList: {
        type: Array,
        default: () => []
      },
      rotateMap: {
        type: Number,
        default: 0
      },
      carRelDevicePath: Array,
      airRecordPath: Array,
      carMessage: {
        type: Boolean,
        default: false
      },
      boatMessage: {
        type: Boolean,
        default: false
      },
      fenceList: Array
    },
    computed: {
      statusList() {
        return [
          { type: '2D', title: '平面图' },
          ...(this.isShow3D ? [{ type: '2.5D', title: '2.5D图' }] : []),
          { type: 'Satellite', title: '卫星图' }
        ]
      }
    },
    methods: {
      refresh() {
        //刷新时 恢复中心点位置与层级； 重新开启车辆船舶数据定时器
        this.$parent.setTimeData();
        this.$parent.setTimBoat();
        this.$parent.carMessage = false;
        this.$parent.boatMessage = false;
        this.map.setZoomAndCenter(13, this.centerPos)
      },
      onProjectInfo() {
        let v = { showProjectInfo: true }
        window.parent.postMessage(
          v,
          'https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MjQxNTkxNH0='
        )
      },
      getCenter() {
        this.center = this.map.getCenter()
      },
      getPitch() {
        this.pitch = this.map.getPitch()
      },
      initMap() {
        window._AMapSecurityConfig = {
          // serviceHost: '您的代理服务器域名或地址/_AMapService',
          securityJsCode: this.securityJsCode
        }

        AMapLoader.load({
          key: this.mapkey, // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.DistrictSearch', 'AMap.ControlBar'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          Loca: {
            //是否加载 Loca,缺省不加载
            version: '2.0.0' //Loca 版本,缺省1.3.2
          }
        })
          .then((AMap) => {
            // const controlBar = new AMap.ControlBar({
            //   position: { top: '10px', right: '10px' }
            // })
            this.AMap = AMap
            this.satelliteLayer = new AMap.TileLayer.Satellite()
            this.map = new AMap.Map('map', {
              terrain: true, // 是否展示地形
              ...(this.isShow3D ? { viewMode: '3D' } : {}),
              zoom: this.defaultZoom, // 缩放级别
              zooms: [5, 18], // 缩放范围
              center: this.centerPos,
              mapStyle: this.mapStyle,
              showBuildingBlock: true,
              rotation: this.rotateMap
            })
            this.setMapStatus(this.initMapStatus)
            this.map.on('click', (e) => {
              const position = [e.lnglat.lng, e.lnglat.lat]
              console.log(position, '您点击的坐标=====')
              // setMarker(map, position)
            })
            // this.map.addControl(controlBar) // 添加地图控制器
            this.map.on('zoomchange', () => {
              this.zoom = this.map.getZoom()
            })
            this.loca = new Loca.Container({
              map: this.map
            })
            // 生成地标箭头
            for (let loca of this.locationMarkList) {
              if (loca.type == 'image') {
                this.setMarker(
                  AMap,
                  `<img class='${loca.images[0].class
                  }' src="${require('@/assets/amap/' +
                    loca.images[0].name)}" alt="">`,
                  loca
                )
              } else if (loca.type == 'marker') {
                this.setMarker(AMap, '<div></div>', loca)
              } else {
                this.setMarker(AMap, '<div class="qiao-marker"></div>', loca)
              }
            }
            //方向
            for (let loca of this.directionMarkList) {
              if (loca.type == 'image') {
                this.setMarker(
                  AMap,
                  `<img class='${loca.images[0].class
                  }' src="${require('@/assets/amap/' +
                    loca.images[0].name)}" alt="">`,
                  loca
                )
              } else if (loca.type == 'marker') {
                this.setMarker(AMap, '<div></div>', loca)
              } else {
                this.setMarker(AMap, '<div class="qiao-marker"></div>', loca)
              }
            }
            //信息弹窗
            // for (let loca of this.locationMarkList) {
            //   this.setInfoWindow(AMap, loca)
            // }
            this.setLocationInfoMark(AMap)
            // 生成小船
            if (this.isBoatMarker) {
              this.boatMarker = this.setMarker(
                AMap,
                '<div class="db-marker"></div>',
                {
                  pos: this.roadPath[0],
                  info: this.locationMarkList[0].info
                }
              )
            }
            // 生成主河流
            this.polyline = this.setPolyline(AMap, this.roadPath, {
              strokeColor: this.roadPathColor,
              lineCap: this.roadLineCap
            })
            // 设置支线
            for (let branchPath of this.branchPathList) {
              this.setPolyline(AMap, branchPath, {
                strokeColor: this.roadPathColor
              })
            }
            for (let item of this.markerPathList) {
              this.setPolyline(AMap, item.path, {
                strokeColor: item.strokeColor,
                strokeWeight: item.strokeWeight,
                zIndex: item.zIndex,
                lineCap: item.lineCap
              })
            }
            //电子围栏
            this.fenceList && this.fenceList.forEach(e => {
              let polyline = this.setPolyline(this.AMap, e.coords, {
                strokeColor: 'red',
                lineCap: this.roadLineCap,
                strokeWeight: 2
              })
              // let polygon = new AMap.Polygon({
              //   path: e.coords,  // 使用经纬度数组
              //   // strokeColor: "#FF0000",  // 边界颜色
              //   strokeOpacity: 1,  // 边界透明度
              //   strokeWeight: 2,  // 边界宽度
              //   fillColor: "#00FF00",  // 填充颜色
              //   fillOpacity: 0.4   // 填充透明度
              // });
              // polygon.setMap(this.map);
              // var textMarker = new AMap.Text({
              //   position: e.center,  // 设置文本标注的位置
              //   text: e.name,  // 设置文本内容
              //   style: {
              //     'color': 'lightgreen',
              //     'font-size': '12px',
              //     'font-weight': 'bold',
              //     'background': 'transparent',
              //     'border': 'none'
              //   }
              // });
              // textMarker.setMap(this.map);
            })
          })
          .catch((e) => {
            console.log(e)
          })
      },
      run() {
        if (!this.isPlaying) return
        if (!this.finished) {
          // 获取地图的中心点（随着相机运动，每一帧的中心点数据都会更新）
          var center = this.map.getCenter().toArray()
          this.boatMarker.setPosition(center) // 设置图标的位置到中心点
        }
        requestAnimationFrame(this.run)
      },
      getMapRotation(newPos, oldPos) {
        const x2 = newPos[0]
        const y2 = newPos[1]
        const x1 = oldPos[0]
        const y1 = oldPos[1]
        const angle =
          (Math.atan(Math.abs(y2 - y1) / Math.abs(x2 - x1)) * 180) / Math.PI
        if (x1 - x2 === 0) {
          if (y1 > y2) return 0
          return 180
        }
        if (y1 - y2 === 0) {
          if (x1 > x2) return -90
          return 90
        }
        const quadrant1 = x2 > x1 && y2 > y1
        const quadrant2 = x2 > x1 && y2 < y1
        const quadrant3 = x2 < x1 && y2 < y1
        const quadrant4 = x2 < x1 && y2 > y1
        let mapRotation
        if (quadrant1) mapRotation = -(90 - angle)
        if (quadrant2) mapRotation = -(90 + angle)
        if (quadrant3) mapRotation = 90 + angle
        if (quadrant4) mapRotation = 90 - angle
        console.log(angle, mapRotation)
        return mapRotation
      },
      setMarker(
        AMap,
        content,
        {
          anchor = 'bottom-center',
          pos,
          title,
          isPostMsg,
          url,
          titleConfig = { class: 'db-info' },
          info
        }
      ) {
        const marker = new AMap.Marker({
          position: pos,
          content,
          anchor,
          map: this.map
        })
        if (title) {
          marker.setLabel({
            direction: titleConfig.direction || 'top',
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: `<div class='${titleConfig.class}'>${title}</div>` //设置文本标注内容
          })
        }
        //摄像头路径跳转
        if (url) {
          marker.on('click', () => {
            window.open(url, '_blank')
          })
        }
        if (info) {
          marker.on('click', () => {
            if (this.timeInterval) {
              clearInterval(this.timeInterval)
              this.timeInterval = null
              clearTimeout(this.timeOut)
              this.setInfoWindow(AMap, { pos, info })
            } else {
              this.setLocationInfoMark(AMap)
            }
            if (isPostMsg) {
              // easyv.cloud 页面通信
              window.parent.postMessage(
                { markerTitle: title },
                'https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MjQxNTkxNH0='
              )
            }
          })
        }
        return marker
      },
      setInfoWindow(AMap, { pos, info }) {
        if (info) {
          let imgs = ''
          if (info.images && info.images.length) {
            info.images.forEach((element) => {
              imgs =
                imgs +
                `<img class='${element.class}' src="${require('@/assets/amap/' +
                  element.name)}" alt="">${element.elementText || ''}`
            })
          }
          this.infoWindow = new AMap.InfoWindow({
            anchor: info.anchor,
            content: `<div class="infoWindow ${info.class || ''
              }"><p class="infoWindowText ${info.color}">${info.content
              }</p>${imgs}</div>`
          })
          this.timeOut = setTimeout(() => {
            this.infoWindow.open(this.map, pos)
            clearTimeout(this.timeOut)
          }, 200)
        }
      },
      setLocationInfoMark(AMap) {
        this.locationMarkInfoList = this.locationMarkList.filter(
          (item) => item.info
        )
        if (this.locationMarkInfoList.length) {
          this.setInfoWindow(AMap, this.locationMarkInfoList[0])
          let i = 1
          this.timeInterval = setInterval(() => {
            this.setInfoWindow(AMap, this.locationMarkInfoList[i])
            i++
            if (i == this.locationMarkInfoList.length) {
              i = 0
            }
          }, 4000)
        }
      },
      setPolyline(
        AMap,
        path,
        {
          strokeColor = this.roadColor,
          strokeWeight = 8,
          zIndex = 500,
          lineCap = 'round'
        }
      ) {
        const line = new AMap.Polyline({
          path,
          isOutline: false,
          strokeColor,
          strokeOpacity: this.roadOpacity,
          strokeWeight,
          strokeStyle: 'solid',
          lineJoin: 'round',
          lineCap,
          zIndex,
          map: this.map,
        })
        line.on('click', (e) => {
          const position = [e.lnglat.lng, e.lnglat.lat]
          console.log(position, '您点击的坐标=====')
          // setMarker(map, position)
        })
        return line
      },
      // 设置地图状态
      setMapStatus(status) {
        this.map.setRotation(this.rotateMap)
        this.isStatusMenuShow = false
        this.map.setZoomAndCenter(13, this.centerPos)
        switch (status) {
          case '2D':
            this.map.remove([this.satelliteLayer])
            this.map.setPitch(0, false, 500)
            break
          case '2.5D':
            this.map.setPitch(55, false, 500)
            break
          case 'satelliteAnd2.5D':
            this.map.add(this.satelliteLayer)
            this.map.setPitch(55, false, 500)
            break
          default:
            this.map.setPitch(0, false, 500)
            this.map.add(this.satelliteLayer)
            break
        }
      },
      queryBd(loca) {
        this.isDbMenuShow = false
        this.map.setZoomAndCenter(15, loca.pos)
        if (loca.info) {
          clearInterval(this.timeInterval)
          this.timeInterval = null
          clearTimeout(this.timeOut)
          if (this.oldMaker == JSON.stringify(loca)) {
            this.setLocationInfoMark(this.AMap)
          } else {
            this.setInfoWindow(this.AMap, loca)
          }
        }
        this.oldMaker = JSON.stringify(loca)
      },
      setPlayMapStatus() {
        this.map.setZoom(17)
        this.map.setPitch(64)
      },
      onStart() {
        clearInterval(this.timeInterval)
        this.infoWindow?.close()
        this.setPlayMapStatus()
        this.boatMarker.setMap(this.map)
        this.loca.animate.start()
        this.isPlaying = true
        this.run()
        this.finished = false
        let rotateTimeOut = setTimeout(() => {
          clearTimeout(rotateTimeOut)
          this.map.setRotation(this.rotateMap)
        })
        this.loca.viewControl.addTrackAnimate(
          {
            path: this.roadPath, // 镜头轨迹，二维数组，支持海拔
            duration: 200000, // 时长
            timing: [
              [0, 0.3],
              [1, 0.7]
            ], // 速率控制器
            rotationSpeed: 1 // 每秒旋转多少度
          },
          function () {
            this.finished = true
            console.log('完成')
          }
        )
      },
      onResume() {
        this.setPlayMapStatus()
        this.isPause = false
        this.finished = false
        this.loca.viewControl.resumeAnimate()
      },
      onPause() {
        this.finished = true
        this.isPause = true
        this.loca.viewControl.pauseAnimate()
      },
      onCancel() {
        this.isPlaying = false
        this.finished = true
        this.loca.viewControl.clearAnimates()
        this.boatMarker.setMap(null)
        this.setMapStatus(this.initMapStatus)
        this.setLocationInfoMark(this.AMap)
      }
    },
    watch: {
      //船舶航线
      airRecordPath: {
        handler(val) {
          //如果之前存在运行路径，清除之前的
          if (this.airRecordlineList.length > 0) {
            this.airRecordlineList.forEach(e => {
              e.setMap(null);
            })
          }
          if (this.boarPointMarks.length > 0 && !this.boatMessage) {
            this.boarPointMarks.forEach(e => {
              e.setMap(null);
            })
          }
          setTimeout(_ => {
            val.forEach(e => {
              let value = e[Object.keys(e)[0]]
              if (value.length > 0) {
                let polyline = this.setPolyline(this.AMap, value, {
                  strokeColor: 'rgb(206,80,243)',
                  lineCap: this.roadLineCap
                })
                this.airRecordlineList.push(polyline);
                polyline.setMap(this.map);
                if (!this.boatMessage) {
                  let boatMark = this.setMarker(
                    AMap,
                    `<img class='car-icon' src="${require('@/assets/amap/boat1.png')}" alt="">`,
                    {
                      pos: e[Object.keys(e)[0]][0],
                      title: Object.keys(e)[0],
                    }
                  )
                  this.boarPointMarks.push(boatMark);
                }
              }
            })
            //点击车辆定位，将当前点设为中心点，并放大
            if (this.boatMessage) {
              const key = Object.keys(val[0])[0]
              this.map.setZoomAndCenter(14, val[0][key][0])
            }
          }, 1000)
        },
        immediate: true,
        deep: true
      },

      //车辆行驶轨迹
      carRelDevicePath: {
        handler(val) {
          //如果之前存在运行路径，清除之前的
          if (this.carPolylineList.length > 0) {
            this.carPolylineList.forEach(e => {
              e.setMap(null);
            })
          }
          //清空点位信息
          if (this.carPointMarks.length > 0 && !this.carMessage) {
            this.carPointMarks.forEach(e => {
              e.setMap(null);
            })
          }
          setTimeout(_ => {
            val.forEach(e => {
              let value = e[Object.keys(e)[0]]
              let carPolyline = this.setPolyline(this.AMap, value, {
                strokeColor: 'rgb(136.107,167)',
                lineCap: this.roadLineCap
              })
              //定时刷新时重新生成车点位信息。如果是通过点击定位的不需要
              if (!this.carMessage) {
                let carMark = this.setMarker(
                  AMap,
                  `<img class='car-icon' src="${require('@/assets/amap/car.png')}" alt="">`,
                  {
                    pos: e[Object.keys(e)[0]][0],
                    title: Object.keys(e)[0],
                  }
                )
                this.carPointMarks.push(carMark);
              }
              this.carPolylineList.push(carPolyline);
              carPolyline.setMap(this.map);
            })
            //点击车辆定位，将当前点设为中心点，并放大
            if (this.carMessage) {
              const key = Object.keys(val[0])[0]
              this.map.setZoomAndCenter(14, val[0][key][0])
            }
          }, 1000)
        },
        immediate: true,
        deep: true
      },

      mapStatus(newV) {
        this.setMapStatus(newV)
      },
    },
    mounted() {
      this.initMap()
    },
    beforeDestroy() {
      this.map.destroy()
      clearInterval(this.timeInterval)
      clearTimeout(this.timeOut)
    }
  }
</script>

<style lang="less" scoped>
  #map {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100vh;
    position: relative;
    margin: 0 auto;

    .operation-light {
      border: 1px solid;
      border-image: linear-gradient(135deg,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0)) 1 1;
      background: rgba(255, 255, 255, 0.11) rgba(255, 255, 255, 0.12);
    }

    .operation-dark {
      background: url(../../../assets/amap/operation-bg-dark.png);
      background-size: cover;
    }

    .operation-container {
      width: 320px;
      height: 66px;
      padding: 0 64px;
      box-sizing: border-box;
      backdrop-filter: blur(30px);
      position: absolute;
      left: 50%;
      margin-left: -160px;
      bottom: 115px;
      z-index: 998;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .operation-icon {
        width: 58px;
        height: 64px;
        line-height: 66px;
        padding: 12px 12px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

        img {
          width: 30px;
          height: 30px;
        }

        .menu-group {
          position: absolute;
          width: 150px;
          background: #eceff3;
          bottom: 65px;
          left: -50px;
          z-index: 999;
          max-height: 600px;
          overflow-y: scroll;

          li {
            width: 100%;
            height: 40px;
            text-align: center;
            background: #eceff3;
            font-size: 16px;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #343434;
            line-height: 40px;

            &:hover {
              background: #008e8e;
              color: #ffffff;
            }

            div {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .demo-title {
    position: absolute;
    top: 50px;
    left: 200px;
    z-index: 1;
  }

  h1 {
    margin: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  h3 {
    font-weight: normal;
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.75);
  }

  .start-btn {
    position: absolute;
    /* bottom: 20px; */
    right: 20px;
    padding: 0 18px;
    height: 30px;
    background-color: #1a66ff;
    border-radius: 5px;
    z-index: 1;
    cursor: pointer;
  }

  .btn1 {
    bottom: 140px;
  }

  .btn2 {
    bottom: 80px;
  }

  .btn3 {
    bottom: 20px;
  }

  .start-btn>a {
    color: #fff;
    display: block;
    height: 100%;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
  }

  :deep(.amap-info:has(.infoWindow)) {
    .img-sign {
      width: 150px;
      margin-top: 5px;
    }

    .infoWindow {
      max-width: 500px;
    }

    .amap-info-outer {
      border-radius: 6px;
    }

    .amap-info-outer:has(.red) {
      border: 1px solid #a81b16;
    }

    .amap-info-content:has(.red) {
      color: #a81b16;
    }

    .amap-info-outer:has(.purple) {
      border: 1px solid #a59aca;
    }

    .amap-info-content:has(.purple) {
      color: #a59aca;
    }

    .amap-info-outer:has(.blue) {
      border: 1px solid #5dc2d0;
    }

    .amap-info-content:has(.blue) {
      color: #5dc2d0;
    }

    .amap-info-outer:has(.green) {
      border: 1px solid #6ab62e;
    }

    .amap-info-content:has(.green) {
      color: #6ab62e;
    }

    .amap-info-contentContainer:has(.bg_green) {
      .amap-info-content {
        color: #fff;
        background-color: #6ab62e;
      }

      .amap-info-close {
        color: #fff;
      }

      .amap-info-sharp {
        border-top-color: #6ab62e;
      }
    }

    .amap-info-contentContainer:has(.bg_green_img) {
      .amap-info-content {
        padding: 0;
      }

      .img-sign {
        width: 220px;
        margin-top: 5px;
      }

      .amap-info-content {
        color: #fff;
        background-color: #6ab62e;
      }

      .amap-info-close {
        color: #fff;
      }

      .amap-info-sharp {
        border-top-color: #6ab62e;
      }
    }
  }
</style>

<style>
  .db-marker {
    width: 40px;
    height: 50px;
    background: url('../../../assets/amap/dibiao.png');
    background-size: 44px 52px;
  }

  .qiao-marker {
    width: 30px;
    height: 38px;
    background: url('../../../assets/amap/bridge.png');
    background-size: cover;
  }

  .boat-marker {
    width: 50px;
    height: 50px;
    background: url('../../../assets/amap/boat.png');
    background-size: 44px 52px;
  }

  .db-info {
    padding: 0 6px;
    /* height: 41px; */
    background: rgba(48, 32, 10, 0.32);
    /* color: #fff; */
    height: 22px;
    font-size: 14px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 800;
    color: yellow;
    line-height: 22px;
  }

  .cs-info {
    padding: 0 6px;
    /* height: 41px; */
    background: rgba(5, 25, 37, 0.75);
    /* color: #fff; */
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 22px;
  }

  .pile-info {
    font-size: 14px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 800;
    color: red;
  }

  .arrow-info {
    font-size: 14px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 800;
    color: yellow;
  }

  .amap-marker-label {
    border: none;
    background: none;
  }

  .bianyaqi-marker {
    width: 14px;
  }

  .gang-marker {
    width: 20px;
  }

  .zhuanghao-marker {
    width: 22px;
  }

  .zhuanghao-rotate-marker {
    width: 22px;
    rotate: 180deg;
  }

  .arrow-start-marker {
    width: 16px;
    rotate: 83deg;
    transform: translate(-70%, 0);
  }

  .arrow-end-marker {
    width: 16px;
    rotate: 260deg;
    transform: translate(50%, 0);
  }

  .dy-arrow-start {
    width: 25px;
    rotate: -90deg;
  }

  .dy-arrow-end {
    width: 25px;
    rotate: 90deg;
  }

  .jkTitle {
    font-size: 14px;
    color: yellow;
  }

  .jkMaker {
    width: 25px;
  }

  .textCenter {
    text-align: center;
  }

  .changshan-img {
    margin-right: 10px;
    width: 30px;
    margin-top: 5px;
  }

  .changshan-qizi {
    width: 20px;
  }

  .changshan-marker {
    width: 48px;
  }

  .paddding-lr {
    padding: 0 20px;
  }

  .camera {
    width: 40px;
    transform: rotate(180deg);
  }

  .car-icon {
    width: 30px;
  }

  .el-icon-refresh-left {
    font-size: 34px;
    color: rgb(90, 221, 250);
    line-height: 49px !important;
  }
</style>