import Vue from 'vue'
import App from './App.vue'
import './importElementUI'
import router from './routers'
import store from './store'
import Router from 'vue-router'
import fullscreen from 'vue-fullscreen'
import './style/index.css'
import VueDeviceDetector from 'vue-device-detector'

Vue.config.productionTip = false

Vue.use(VueDeviceDetector)
Vue.use(Router)
Vue.use(store)
Vue.use(fullscreen)
Vue.prototype.$fullscreen = fullscreen
Vue.prototype.$store = store


if (window.__POWERED_BY_WUJIE__) {
  let instance
  window.__WUJIE_MOUNT = () => {
    instance = new Vue({
      router,
      render: (h) => h(App)
    }).$mount('#app')
  }
  window.__WUJIE_UNMOUNT = () => {
    instance.$destroy()
  }
} else {
  new Vue({
    router,
    render: (h) => h(App)
  }).$mount('#app')
}
