import { render, staticRenderFns } from "./Jiaxing320Live.vue?vue&type=template&id=cc63a684&scoped=true"
import script from "./Jiaxing320Live.vue?vue&type=script&lang=js"
export * from "./Jiaxing320Live.vue?vue&type=script&lang=js"
import style0 from "./Jiaxing320Live.vue?vue&type=style&index=0&id=cc63a684&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc63a684",
  null
  
)

export default component.exports