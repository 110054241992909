import request from '@/utils/axios'

export const api = {
	getCarRelDeviceReport: '/admin/page/crudQuery/carRelDeviceReport',
	getCarRelDevice: '/admin/page/crudQuery/carRelDevice',
	getProjectAirRecord: '/api/airRecord/list',
	getprojectAir: '/admin/page/crudQuery/projectAir'
}

export function getCarRelDeviceReport(data) {
	return request({
		url: api.getCarRelDeviceReport,
		method: 'post',
		data
	})
}

export function getCarRelDevice(data) {
	return request({
		url: api.getCarRelDevice,
		method: 'post',
		data
	})
}

export function getProjectAirRecord(data) {
	return request({
		url: api.getProjectAirRecord,
		method: 'get',
		data,
		baseURL: 'https://pmcp.zjkgs.cn'
	})
}

export function getprojectAir(data) {
	return request({
		url: api.getprojectAir,
		method: 'post',
		data
	})
}